import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import YMO_Logo from "../../images/ymo-all-green.png";
import "./index.scss";
import Overlay from "../overlay";
import ScrollLock, { TouchScrollable } from "react-scrolllock";

const Header = (props) => {
  const [isOpen, setOpenState] = useState(false);

  const links = [
    {
      text: "needle",
      url: "/#nih",
    },
    {
      text: "youth night",
      url: "/#yn",
    },
    {
      text: "mentoring",
      url: "/#mentoring",
    },
    {
      text: "health crusaders",
      url: "/#hc",
    },
    {
      text: "B2H",
      url: "/#tutoring",
    },
    {
      text: "SST",
      url: "/#senior-social-time",
    },
    {
      text: "Food Pantry",
      url: "/#star",
    },
    {
      text: "event",
      url: "/#events",
    },
    // {
    //   text: "gala",
    //   url: "/#gala",
    // },
    // {
    //   text: "shop",
    //   url: "/shop",
    // },
  ];

  return (
    <>
      <section className="ymo-header d-flex align-items-center">
        <div
          style={{
            width: "50px",
            height: "50px",
            marginLeft: "10px",
          }}
        >
          <Link to="/">
            <img className="header__logo" src={YMO_Logo} alt="" />
          </Link>
        </div>
        <span
          className="text-uppercase text-white pl-4 font-weight-bold"
          style={{
            lineHeight: "0.9",
          }}
        >
          Youth <br /> Mission <br /> Outreach
        </span>
        <div className="ml-auto header__nav">
          <ul className="nav">
            {links.map((link) => (
              <li className="nav-item">
                <Link
                  className="nav-link header__link text-uppercase font-weight-bold"
                  to={`${link.url}`}
                >
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
          <Link
            to="/donate"
            className="btn border border-success text-white mr-3 header__btn"
          >
            Donation
          </Link>
        </div>
        <div className="ml-auto mobile__header__nav">
          <button
            className={`nav__icon ${isOpen ? "open" : ""}`}
            onClick={() => setOpenState(!isOpen)}
          >
            <div />
          </button>
        </div>
      </section>
      {isOpen ? (
        <ScrollLock>
          <Overlay links={links} setOpenState={setOpenState} isOpen={isOpen} />
        </ScrollLock>
      ) : (
        <div />
      )}
    </>
  );
};

Header.propTypes = {};

export default Header;
