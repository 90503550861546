import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import "./index.scss";

const Overlay = ({ links, setOpenState, isOpen }) => {
  return (
    <section className="overlay__nav">
      <ul>
        {links.map((link) => (
          <li>
            <Link
              className="overlay__link"
              to={`${link.url}`}
              onClick={() => setOpenState(!isOpen)}
            >
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
      <div className="d-flex justify-content-center">
        <Link
          to="/donate"
          className="btn border border-success text-white header__btn"
        >
          Donation
        </Link>
      </div>
    </section>
  );
};

Overlay.propTypes = {
  links: PropTypes.array.isRequired,
  setOpenState: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default Overlay;
