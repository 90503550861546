import React from "react";
import PropTypes from "prop-types";
import "../../scss/custom.scss";
import Header from "../header";
import Footer from "../footer";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    easing: "easeInOutCubic",
    offset: 130
  });
}

const Layout = ({ children }) => (
  <>
    <Header />
    <main
      style={{
        fontFamily: "Roboto, sans-serif",
        overflowX: "hidden",
        height: "100%",
        minHeight: "70vh"
      }}
    >
      {children}
    </main>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
