import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import "./index.scss";

const Footer = props => {
  return (
    <footer>
      <div className="container pt-5">
        <div className="footer__inner row">
          <div className="col-md follow_us">
            <h4>Follow Us</h4>
            <a
              className="text-white"
              href="https://www.facebook.com/YouthMissionOutreach"
              rel="noreferrer"
              target="_blank"
            >
              <span className="pr-2">
                <FaFacebookSquare />
              </span>
            </a>
            <a
              className="text-white"
              href="https://www.instagram.com/i_am_ymo/"
              rel="noreferrer"
              target="_blank"
            >
              <span>
                <FaInstagram />
              </span>
            </a>
          </div>
          <div className="col-md">
            <h4>Related Links</h4>
            <ul
              className="pl-0 ml-0"
              style={{
                listStyle: "none"
              }}
            >
              <li>
                <Link to="/about#welcome">About Us</Link>
              </li>
              <li>
                <Link to="/about#contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-md">
            <h4>Resource Links</h4>
            <ul
              className="pl-0 ml-0"
              style={{
                listStyle: "none"
              }}
            >
              <li>
                <a
                  href="https://www.bible.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  YouVersion - Bible
                </a>
              </li>
              <li>
                <a
                  href="https://www.biblegateway.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Bible Gateway
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-5 h-100 d-flex">
          <div className="col-md-6 d-flex text-white mt-auto pb-2">
            © {new Date().getFullYear()}, YMO
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
